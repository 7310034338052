<template>
  <div>
    <vs-input
      type="file"
      :accept="acceptedTypes"
      :multiple="isMultiple"
      @change="handleFileSelection($event)"
      class="w-full"
      v-model="selectedFile"
      :disabled="isDisabled"
    />
    <!-- <p v-if="errorMessage" class="error">{{ errorMessage }}</p> -->
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const dmsRepository = RepositoryFactory.get("dms");

export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    acceptedTypes: {
      type: Array,
      required: true,
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    setPathUpload: {
      type: String,
      default: "",
    },
    isWithAPI: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedFile: null,
      errorMessage: "",
    };
  },
  methods: {
    setDisabled(value) {
      this.isDisabled = value;
    },
    async handleFileSelection(event) {
      const files = event.target.files;
      var fileData = files[0];
      let fileName = fileData.name;

      console.log("fileData", fileData)
      console.log("fileName", fileName)

      var format = /[`@#$%^&*\[\]{};':"\\|<>\/?~]/; // eslint-disable-line
      var format_single = / [ ` @ # $ % ^ & * [ ] { } ; ' : " | < > \ ? ~ ] /; // eslint-disable-line
      
      if(format.test(fileName)){
        console.log("fileName TIDAK AMAN")
        this.$vs.dialog({
          color: "danger",
          title: `Warning | Invalid file name`,
          text: "Please rename file. \nFilename cannot contain these characters. " + format_single,
        });
      } else {
        console.log("safe")
        console.log("masih di exec kok")
        if (files.length > 0) {
          if (this.isWithAPI) {
            await this.postToAPI(files);
          } else {
            if (this.isMultiple) {
              this.$emit("data", files);
            } else {
              this.$emit("data", files[0]);
            }
          }
          // this.errorMessage = "";
        } else {
          this.$emit("data", null);
          // this.errorMessage = "Please select a file";
        }
      }

      this.selectedFile = null;
    },
    async postToAPI(files) {
      this.$vs.loading();
      try {
        // form data
        const formData = new FormData();
        formData.append("path", this.setPathUpload);
        for (let file of files) {
          formData.append("attachments[]", file);
        }
        const resp = await dmsRepository.upload(formData);
        if (resp.code == 200) {
          this.$emit("data", resp.data);
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: error.message,
          color: "danger",
        });
      }

      this.$vs.loading.close();
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
  margin-top: 5px;
}
</style>
